import React, { Component } from 'react';
// import PropTypes from 'prop-types';

class Left extends Component {

    constructor(props){
        super(props);
        this.state={
            nav_class:"physician-information",           
        }
    }
    SubMenu = (e) =>{
        e.preventDefault();
        console.log('toggle method call ');
        var element = document.getElementById("sub-menu");
        element.classList.toggle("active");

    }
   

    render() {

        let activeMenu = this.props.activeMenu == undefined ? 'physician-information' : this.props.activeMenu;
        return (
        <div className="left-section"> 
            <ul className="left-nav">
            <li  className={ activeMenu == 'patient-activity' ? "active" : ''}><a   href="../patient-activity">Patient Activity</a></li>
            <li  ><a className="menu-item-nav" onClick={this.SubMenu} href="javascript:void(0)">Register New Client </a>
            <ul id="sub-menu" className={ activeMenu == 'initial-processing' ? "active sub-menu" : activeMenu == 'client-consent' ? "active sub-menu" : activeMenu == 'infectious-disease' ? "active sub-menu" : 'sub-menu'}>
            <li className={ activeMenu == 'initial-processing' ? "active" : ''}><a href="../initial-processing">New Sample Form</a></li>
            <li className={ activeMenu == 'client-consent' ? "active" : ''} ><a  href="../client-consent">New Client Consent Form</a></li>
            <li className={ activeMenu == 'infectious-disease' ? "active" : ''}><a href="../infectious-disease">New Infectious Disease Form</a></li>
             </ul>
            </li>
           <li className={ activeMenu == 'ACT-banking-storage-plans' ? "active" : ''}><a href="../ACT-banking-storage-plans">ACT Banking and Storage Plans</a></li>
            <li className={ activeMenu == 'stem-cell-request' ? "active" : ''}><a href="../stem-cell-request">New Stem Cell Request Form</a></li>
            <li className={ activeMenu == 'store' ? "active" : ''}><a href="../Store">ACT Store</a></li>
            <li className={ activeMenu == 'payment-information' ? "active" : ''}><a href="../payment-information">Update Payment Information</a></li>
            <li className={ activeMenu == 'resources' ? "active" : ''}><a href="../resources">Resources</a></li>
            <li  className={ activeMenu == 'physician-information' ? " active" : ''}><a href="../physician-information">Physician Information</a></li>
            
            
            
          
            
            
           
          
           {/* <li><a href>Order Patient Cells</a></li> */}
          
       
           
            </ul>
        </div>
        );
    }
}

// Left.propTypes = {

// };

export default Left;