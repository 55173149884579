/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
// import { browserHistory } from 'react-router'
import  { Redirect } from 'react-router-dom'
// import  { useRef   }  from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import validator from 'validator';
import MainLayout from "../layout/main-layout";

import { login} from '../../actions/payActions'
// import DatePicker from 'react-date-picker'
// import Moment from 'moment';
// import SignatureCanvas from 'react-signature-canvas'
// import DatePicker from "react-datepicker";
// import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
 class home extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{ Source:'ACT Physician Portal'},
            login:true,
            loading:false
        }
    }

    componentWillReceiveProps(nextProps) {
    // console.log('nextProps ----------',nextProps); 
    // console.log('json', JSON.parse(nextProps.page_name.res))   
    let result = JSON.parse(nextProps.page_name.res) 
    // console.log('data', result.message)  
    
    if(nextProps.page_name){
        this.setState({message:result.message});
        this.setState({formData:{}})  
        this.setState({'login':result.login})
    }
    
    if(result.login){
        // browserHistory.push('/physician-inforation')
        localStorage.setItem('myData', 'hello');    
    }
     
        
    Array.from(document.querySelectorAll("input")).forEach(
        input => (input.value = "")
        );
        
    
    
    
              
        // let element = 
    }  

   

    onChange = (e) => {  
        this.state.formData[e.target.id] = e.target.value; 
        // console.log('form vlaue=>', this.state.formData);
    }

    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;
        
        if(!fields["username"]){
            formIsValid = false;
            errors["username"] = "Please enter username";
            let element = document.getElementById("username");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["password"]){
            formIsValid = false;
            errors["password"] = "Please enter password";
            let element = document.getElementById("password");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});
        
        if(formIsValid){
            // this.setState({ loading: true });  
            const newList = this.state.formData;
            // console.log("newlist=>", newList);
            this.props.login(newList, this.props.history);
            this.setState({loading:false})
            }

    }

    render() {
        const { login } = this.state;
        return (
            <MainLayout>
                <title>American Cell Technology</title>
                {/* <Header></Header> */}

                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="images/spinner.svg" alt="" /></div>
                </div>:""}
                <div className="login-wrapper">
                    <div className="login-container">
                        <div className="login-logo-area"><img src="images/logo-main.svg" alt="" /></div>
                        <div className="login-box">
                            {!(login)?<span style={{color:'red'}}>Username or Password not matched, Please try again.</span>:''}                            
                            <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                                <div className="form-group">
                                <label>Username</label>
                                <input type="email" id="username" className="form-control" placeholder="Enter Username" onChange={this.onChange} />
                                <small id="userHelp" className="form-text text-muted">We'll never share your username with anyone else.</small>
                                <span style={{color: "red"}}>{this.state.errors["username"]}</span>
                                </div>
                                <div className="form-group">
                                <label>Password</label>
                                <input type="password" id="password" className="form-control" placeholder="Enter Password" onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["password"]}</span>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-full">Sign In</button>
                                </div>
                                
                                <div className="form-group custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck" name="example1" />
                                <label className="custom-control-label" for="customCheck">Remember me</label>
                                </div>

                                <div className="forgotpass-link">
                                    <a href="../forgot-password">Forgot Your Password?</a>
                                    <a href="../physician-agreement" >Not a Member yet?</a>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
                
            </MainLayout>
        );
    }
}

home.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    login:PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {login})(home);
