/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import  { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
import SignatureCanvas from 'react-signature-canvas'

// import ReactSession from 'react-client-session';
import { UpdateMember   } from '../../actions/payActions'


class MemberAgreement extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{Source:'ACT Physician Portal'},
            card_info:{},
            login:true,
            page:1,
            payment_method:"",
            // member_info:"",
            Credit_Card_Number:"",
            update_card:false,
            save_card:false,
            transaction_id:"dd",
            member_response:"",
            Dob:'',
            Ship_date:'',
            card_last_gid:'',
            CreditCard:"",
            api_error:"",
            loading:false,

        }
    }

    componentDidMount() {   
    }

    componentWillReceiveProps(nextProps) {

        //  console.log('nextProps ----------',nextProps.page_name); 
        // var result = JSON.parse(nextProps.page_name.member_info);
        // console.log('res  ----------- ',result)


        if(nextProps.page_name.member_info){

            this.setState({loading:false});
            var result = JSON.parse(nextProps.page_name.member_info);
            if(result.error){
                console.log('error ----------- ',result.error);
                this.setState({api_error:result.error})
            }else{
              
                this.setState({member_response:result.message})
            }
            
            this.setState({loading:false});
            window.scrollTo(0, 0);     

        }

    }

    clear = () => {
        this.sigPad.clear()
        this.setState({ trimmedDataURL: null })
      }
    
    trim = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
        .toDataURL('image/png') })
        this.state.formData['Signature'] = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        // console.log('img', this.sigPad.getTrimmedCanvas().toDataURL('image/png'));
        
    }

    onChange = (e) => {  
        this.state.formData[e.target.id] = e.target.value; 
        // console.log('form vlaue=>', this.state.formData);
    }

    onSubmit = (e) => {

        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;

        if(!fields["FirstName"]){
            formIsValid = false;
            errors["FirstName"] = "Please enter first name";
            let element = document.getElementById("FirstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["LastName"]){
            formIsValid = false;
            errors["LastName"] = "Please enter last name";
            let element = document.getElementById("LastName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Email"]){
            formIsValid = false;
            errors["Email"] = "Please enter email";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if (fields["Email"] != null && !validator.isEmail(fields["Email"])) {
            formIsValid = false;
            errors["Email"] = "Please enter valid Email";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
          }

        if(!fields["PhoneNumber"]){
            formIsValid = false;
            errors["PhoneNumber"] = "Cannot be empty";
            let element = document.getElementById("PhoneNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(isNaN(fields["PhoneNumber"]) && fields["PhoneNumber"]){
            formIsValid = false;          
            errors["PhoneNumber"] = "Please insert number only";
            let element = document.getElementById("PhoneNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         }


        if(!fields["Signature"]) {
            formIsValid = false;
            errors["Signature"] = "Please sign and click on check button.";
            // console.log('errors',errors);
            let element = document.getElementById("Signature");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }

         
        if(!fields["FacilityPhoneNumber"]){
            formIsValid = false;
            errors["FacilityPhoneNumber"] = "Cannot be empty";
            let element = document.getElementById("FacilityPhoneNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(isNaN(fields["FacilityPhoneNumber"]) && fields["FacilityPhoneNumber"]){
            formIsValid = false;          
            errors["FacilityPhoneNumber"] = "Please insert number only";
            let element = document.getElementById("FacilityPhoneNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         }

         

         
        if(!fields["MedicalLicenseNumber"]){
            formIsValid = false;
            errors["MedicalLicenseNumber"] = "Cannot be empty";
            let element = document.getElementById("MedicalLicenseNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // if(isNaN(fields["MedicalLicenseNumber"]) && fields["MedicalLicenseNumber"]){
        //     formIsValid = false;          
        //     errors["MedicalLicenseNumber"] = "Please insert number only";
        //     let element = document.getElementById("MedicalLicenseNumber");                
        //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({top: y, behavior: 'smooth'});
        //     element.className = "form-control error"
        //  }




        if(!fields["ZipCode"]){
            formIsValid = false;
            errors["ZipCode"] = "Please enter Zip Code";
            let element = document.getElementById("ZipCode");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(isNaN(fields["ZipCode"]) && fields["ZipCode"]){
            formIsValid = false;          
            errors["ZipCode"] = "Please insert number only";
            let element = document.getElementById("ZipCode");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         }

         

        if(!fields["CompanyName"]){
            formIsValid = false;
            errors["CompanyName"] = "Please enter Company Name";
            let element = document.getElementById("CompanyName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }


        

        if(!fields["Street"]){
            formIsValid = false;
            errors["Street"] = "Please enter Street";
            let element = document.getElementById("Street");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["City"]){
            formIsValid = false;
            errors["City"] = "Please enter City";
            let element = document.getElementById("City");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["State"]){
            formIsValid = false;
            errors["State"] = "Please enter State";
            let element = document.getElementById("State");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }


        if(!fields["Country"]){
            formIsValid = false;
            errors["Country"] = "Please enter Country";
            let element = document.getElementById("Country");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        this.setState({errors: errors});

        if(formIsValid){
            // this.setState({ loading: true });  
            this.setState({form_edit:false})
            this.setState({form_disable:true})
            const newList = this.state.formData;
            // console.log("newlist=>", newList);
            this.props.UpdateMember(newList, this.props.history);
            this.setState({loading:true})
            }




       


    }


    render() {
        
        const {formData} = this.state;
             
        return (
            <MainLayout>
                {/* <Header /> */}
                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="images/spinner.svg" alt="" /></div>
                </div>:""}
                <title>American Cell Technology</title>

                {/* <div className=""> */}
                {/* <Left activeMenu={"initial-processing"}/>  */}
                <div className="login-wrapper">

                <div className="login-container">
            
                <div className="login-logo-area"><img src="images/logo-main.svg" alt="" /></div>
                        
                <div style={{width:"90%",marginLeft:"5%",background:"#fff",padding:"20px",borderRadius: "4px",border: "solid 1px #D8DDE6"}}>
                { (this.state.member_response)?
                    <div className="col-md-12">
                        <h5 style={{paddingTop:"10px"}}><i>{this.state.member_response}</i> </h5>
                    </div>:                    
                    <div>
                    <h2 className="title-2">PHYSICIAN/PRACTITIONER ATTACHMENT TO STEM CELL PROCESSING AND STORAGE AGREEMENT</h2>
                    <span style={{color: "red"}}>{this.state.api_error}</span> 
                    <div className="row">
                        <div className="col-md-12">
                            <p>The undersigned physician/practitioner (“Physician”) herby agrees to the terms set forth in this Physician Attachment to the Stem Cell Processing and Storage Agreement (the “Attachment”):</p>
                        </div>
                    </div>
                    <h2 className="title-2">RECITALS</h2>
                    <div className="row">

                    <div className="col-md-12">
                        <p>
                        A. American Cell Technology, a Florida corporation (“Bank”) and the client of Physician named therein (the “Client”), have entered into a STEM CELL PROCESSING AND STORAGE AGREEMENT (the “Storage Agreement”) for the testing, processing, expanding, freezing, storing, withdrawing, transferring, donating, shipping, transporting and/or other use of the Client’s adipose, muscle and/or bone marrow tissue to be collected by the Physician (the “Tissue”), for the purpose of extracting and storing the stem cells contained in the Tissue (collectively, the “Tissue Banking Services”). 
                        </p>
                        <p>                            
                        B. Physician is willing to provide certain information to the Client and to collect the Tissue from the Client in conjunction with the performance of a liposuction procedure and/or other surgical procedure to be performed on the Client by Physician, in accordance with the terms and conditions set forth in this Attachment.
                        </p>
                        <p>NOW, THEREFORE, in consideration of the execution and delivery of this Attachment, the Storage Agreement and other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledge, Bank and Physician hereby agree as follows:</p>
                    
                    <ol>
                      <li>Recitals; Defined Terms. The foregoing recitals are true and correct and are incorporated herein by this reference. Unless the context otherwise requires, all initial capitalized terms used but not defined in this Attachment shall have the meaning or meaning given to such terms in the Storage Agreement.</li> 
                      <li>Services of Physician. Physician agrees to: <br />
a. Inform the Client regarding the risks of the procedure for collecting the Tissue, as well as the risks and benefits of cryopreservation and transplantation of stem cell;  <br />
b. Collect the Tissue from the Client using a banking ("Banking Kit") furnished by Bank to Physician to store the collected Tissue, provided Physician deems it medically appropriate to do, in the exercise his/her professional medical judgement; and  <br />
c. Make arrangements to deliver, and pay for the delivery of, the collected Tissue to Bank, in accordance with the instructions provided by bank to Physician.</li>
                      <li>Bank and Physician Fees. Collection Fees. Physician agrees to collect the fees for the collection of the Tissue by Physician and Bank's fee for the Tissue Banking Services from the Client. For each tissue sample collected from each Client, Physician shall remit $2500 for Adipose and $5000 for Bone Marrow samples to the Bank as consideration for the Tissue Banking Services to be provided by Bank to the Client, prior to or concurrently with the shipment of the collected Tissue to Bank. Physician may retain the balance of the fee collected from the Client as compensation for Physician's services to the Client in connection with the collection and delivery of the Tissue.Bank has right at any time to change or amend pricing.</li>  
                      <li>Relationship of the Parties. Physician acknowledges and agrees that he/she is an independent contractor of Bank and nothing contained herein shall be construed so as to give Physician the authority to bind Bank, or make representations, promises, guarantees, warranties, covenants, contracts, agreements, or otherwise act for or on behalf of Bank, and nothing contained herein shall be construed so as to make Physician the agent, representative, employee, joint venturer or partner of Bank.</li>  
                      <li>Release from Liability. Physician hereby, for him or herself, his or her agents, attorneys, successors-in-interest, affiliates, representative, heirs and assigns, irrevocably and unconditionally releases and discharges Bank and its shareholders, directors, officers, employees, agents, representatives, affiliates, and/or their respective legal representatives, estates, successors and assigns, from and against any and all claims, causes of action, rights, liabilities, damages, costs, and expenses, known and unknown, that Physician, or his or her heirs, assigns, or representatives may incur, resulting from or relating to the testing, processing, expanding, freezing, storing, withdrawing, transferring, donating, shipping, transporting and/or other use of the Client's Tissue. Without limiting the foregoing, Physician further acknowledges that Bank is not responsible in any way for the actions of Physician and/or others, including, as applicable and without limitation, the medical facility, the staff of the medical facility, laboratory staff, and transporters of the Tissue. Physician understands that by agreeing to this Release from Liability he or she is giving up rights they might otherwise have now or in the future to seek money damages or other remedies or relief from Bank, its affiliates, agents, managers and other persons and entities referenced in this Release from Liability.</li>
                      <li>Indemnification. Physician agrees to indemnify and hold harmless Bank and its officers, shareholders, directors, employees, agents (including marketing agents), representatives, affiliates, and/or their respective legal representatives, estates, successors and assigns (Bank and each other person or entity being referred to herein as an "Indemnified Person"), from and against all claims, liabilities, losses or damages (or actions in respect thereof) or other expenses that: 
                          < br/>
                          a. Are related to or arise out of actions taken or omitted to be taken by Physician, its agents or its employees (including any untrue statements made or any statements omitted to be made); or <br />
b. Are otherwise related to or arise out of actions taken by Physician, its agents or its employees in connection with the information provided to, and the collection of the Tissue from, the Client.
                      </li>
                      <li>Assignment;Delegation. Bank may assign its rights and delegate its obligations under this Attachment at any time and in its sole discretion, without further consent of Physician. Bank may perform its obligations hereunder through subcontractors, agents or managers pursuant to management services agreements. This Attachment is not assignable by Physician without the prior written consent of Bank and any attempted assignment in breach of the foregoing restriction shall be void.
</li>

                      

                    </ol>

                    <p>By signing below, Physician/Practitioner confirms that he/she has read and fully understands the terms and conditions of this Physician Attachment to the Stem Cell Processing and Storage Agreement.</p>
                    
                    
                    </div>


                    </div>    
                    <form className="needs-validation" noValidate onSubmit={this.onSubmit}>

                    <div className="form-wrap glove-form">
                    <h2 className="title-2">Physician Information</h2>

                    <div class="row">                          
                        
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">First Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="FirstName" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["FirstName"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Last Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="LastName" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["LastName"]}</span>
                                </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">Email <span className="required">*</span></label>
                                    <div className="field-col">
                                    <input type="text" id="Email" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["Email"]}</span>
                                    </div>
                                </div>
                                </div>
                            

                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Phone Number <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="PhoneNumber" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["PhoneNumber"]}</span>
                                </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Medical License Number <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="MedicalLicenseNumber" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["MedicalLicenseNumber"]}</span>
                                </div>
                                </div>
                            </div>




                    </div>
                    <h2 className="title-2">Facility Information</h2>

                    
                    <div className="row">
                    <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Your Company Name/Facility Name <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="CompanyName" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["CompanyName"]}</span>
                                </div>
                                </div>
                            </div>
                            

                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Phone Number <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="FacilityPhoneNumber" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["FacilityPhoneNumber"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Street <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Street" className="form-control" onChange={this.onChange} placeholder="" />
                                    <span style={{color: "red"}}>{this.state.errors["Street"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">City <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="City" className="form-control" onChange={this.onChange} placeholder="" />
                                    <span style={{color: "red"}}>{this.state.errors["City"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">State <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="State" className="form-control" onChange={this.onChange} placeholder="" />
                                    <span style={{color: "red"}}>{this.state.errors["State"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Zip Code <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="ZipCode" className="form-control" placeholder="" onChange={this.onChange} />
                                    <span style={{color: "red"}}>{this.state.errors["ZipCode"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Country <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Country" className="form-control" onChange={this.onChange} placeholder="" />
                                    <span style={{color: "red"}}>{this.state.errors["Country"]}</span>
                                </div>
                                </div>
                            </div>
                    </div>
                    <div className="row"> 
                    <div className="col-md-1 mb-6"></div>         
                        <div className="col-md-9 mb-6">
                            <p>By signing below, you confirm that you have read and fully understand the terms and conditions of this Agreement, including both Part I: Informed Consent and Part II: Client Service Agreement terms and Conditions.</p>
                        <label> <span id="Signature"   className="act_required"></span></label>
                        <SignatureCanvas  ref={(ref) => { this.sigPad = ref }} penColor='white' backgroundColor="gray" canvasProps={{height: 100, className: 'sigCanvas'}} />
                        
                        <br/>
                        <span style={{color: "red"}}> {this.state.errors["Signature"]}</span>
                        <div>
                            <button className="btn  btn-secondary" type="button"  onClick={this.clear}>
                            Clear
                            </button>  &nbsp;
                            <button className="btn btn-primary" type="button"  onClick={this.trim}>
                            Click to Confirm Signature
                            </button>
                        </div> 
                        
                    </div>
                    </div>

                    <div className="row"> 
                    <div className="col-md-12 mb-6">
                        <br></br>
                        <center>
                    <div className="button-row">
                                <button id="1"  type="button" className="btn btn-outline-primary mr-2">Cancel</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                    </center>
                    </div></div>


                    </div>
                    </form>

                    

                </div>   } 



                </div>

                </div>
                </div>

            </MainLayout>
            
        );
    }
}



MemberAgreement.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    UpdateMember:PropTypes.func.isRequired,    
};



const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {UpdateMember  })(MemberAgreement);


