import axios from "axios";
import { GET_ERRORS, SET_PAY,MAKE_PAY,SET_LOGIN,SET_IPActivity,SET_PAY_INFO,GET_PAY,SEARCH_PATIENT,APPROVE_CELL, SET_DISEASE,SET_PHYSICIAN,SET_CONSENT,GET_OTP,SET_PASS,SET_REQUEST,SET_VIAL,SET_DOSE ,SET_CLIENT, SET_CELL,MEMBER,SHIP_COST} from "./types";





// Edit Country
export const GetPhysicianVialsService = (formdata) => dispatch => {
  console.log('hello')
  axios
    .post("/api/pay/get_PhysicianVialsService",formdata)    
    .then(res => {
      console.log(res);
    	dispatch({ type: SET_VIAL, payload : res.data })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


export const setShippingCost = (formdata) => dispatch => {
  console.log('hello')
  axios
    .post("/api/pay/get_ShippingCost",formdata)    
    .then(res => {
      console.log(res);
    	dispatch({ type: SHIP_COST, payload : res.data })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




 



export const approvePendingCellActivity
= (formdata) => dispatch => {
  console.log('hello')
  axios
    .post("/api/pay/set_approvePendingCellActivity",formdata)    
    .then(res => {
      console.log(res);
    	dispatch({ type: APPROVE_CELL, payload : res.data })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


export const getOtp = (client_email) => dispatch => {
  console.log('client_email => ',client_email)
  axios
    .get("/api/pay/get_otp",{ params: { email: client_email } })    
    .then(res => {
      console.log(res);
    	dispatch({ type: GET_OTP, payload : res.data })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




// Edit Country
export const getPhysician = (id) => dispatch => { 
  axios
    .get("/api/pay/get_Physician",{params: {id:id}} )    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_PAY, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};



export const searchPatientIPActivity = (formdata) => dispatch => { 
  axios
    .post("/api/pay/searchPatientIPActivity",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SEARCH_PATIENT, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const updatePassword = (formdata) => dispatch => { 
  axios
    .post("/api/pay/update_Password",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_PASS, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const updatePhysician = (formdata) => dispatch => { 
  axios
    .post("/api/pay/update_Physician",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_PHYSICIAN, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const sendProcessData = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_ProcessData",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: MAKE_PAY, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const StoragePlan = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_StoragePlan",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: MAKE_PAY, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const sendConsentData = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_ConsentData",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_CONSENT, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};



export const setCellRequest = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_CellRequest",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_REQUEST, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const setStoreRequest = (formdata) => dispatch => { 
  axios
    .post("/api/pay/send_StoreRequest",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_REQUEST, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




export const getPaymentInfo = (id) => dispatch => {
  axios
    .get("/api/pay/get_PaymentInfo",{params: {id:id}})    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: GET_PAY, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );

}

export const verifyClientData = (formdata) => dispatch => {
  console.log('hello')
  axios
    .post("/api/pay/set_verifyClientData",formdata)    
    .then(res => {
      console.log(res);
    	dispatch({ type: SET_CLIENT, payload : res.data })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const setPaymentInfo = (formdata) => dispatch => { 
  axios
    .post("/api/pay/set_PaymentInfo",formdata)    
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_PAY_INFO, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


// Add Country
export const login = (loginData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/login", loginData)
    .then(res => 
      {
        dispatch({ type: SET_LOGIN, payload : res.data })  
        let resp = JSON.parse(res.data.result)
        // console.log('respo ', resp) 
        // console.log('respo login ', resp.login) 
        if(resp.login){
          localStorage.setItem('physician_id',resp.id);
          sessionStorage.setItem('physician_id',resp.id);
          history.push('/physician-information')
        }  
      })
      .then(() => {
        // ReactSession.set("username", "Bob");
        // history.push('/physician-inforation')
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};

export const getPatientDoseActivity = (id) => dispatch => {
  axios
    .get("/api/pay/get_PatientDoseActivity",{params: {id:id}}) 
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_DOSE, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );

}


export const getPendingCellActivity = (id) => dispatch => {
  axios
    .get("/api/pay/get_PendingCellActivity",{params: {id:id}}) 
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_CELL, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );

}


export const SearchDoseActivity = (searchData) => dispatch => {
  axios
    .post("/api/pay/SearchDoseActivity",searchData) 
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_DOSE, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );

}

export const getPatientIPActivity = (id) => dispatch => {
  axios
    .get("/api/pay/get_PatientIPActivity",{params: {id:id}})  
    .then(res => {
      console.log(res.data);
    	dispatch({ type: SET_IPActivity, payload :res.data})
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );

}

// Add Country
export const addPayment = (paymentData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/checkout", paymentData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        dispatch({ type: MAKE_PAY, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




export const setDiseaseData = (formData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/set_DiseaseData", formData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        dispatch({ type: SET_DISEASE, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};


export const addAgreement = (formData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/agreement", formData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        dispatch({ type: MAKE_PAY, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};




export const UpdateMember = (formData, history) => dispatch => {
  // const [page_name] = useState(0);
  axios
    .post("/api/pay/updateMember", formData)
    .then(res => 
      {
        // this.setState({page_name:'congrats'}),
        dispatch({ type: MEMBER, payload : res.data })
        // history.push("/")
      })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    );
};







